import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3ab34149 = () => interopDefault(import('../pages/auth.vue' /* webpackChunkName: "pages/auth" */))
const _5873d6f8 = () => interopDefault(import('../pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _6866ab83 = () => interopDefault(import('../pages/home/index.vue' /* webpackChunkName: "pages/home/index" */))
const _053d99eb = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _7dc35854 = () => interopDefault(import('../pages/streaming/index.vue' /* webpackChunkName: "pages/streaming/index" */))
const _62e9b869 = () => interopDefault(import('../pages/wishlist/index.vue' /* webpackChunkName: "pages/wishlist/index" */))
const _5cca6455 = () => interopDefault(import('../pages/collection/streaming/index.vue' /* webpackChunkName: "pages/collection/streaming/index" */))
const _56ca4720 = () => interopDefault(import('../pages/dashboard/profile/index.vue' /* webpackChunkName: "pages/dashboard/profile/index" */))
const _4d322415 = () => interopDefault(import('../pages/dashboard/transaction/index.vue' /* webpackChunkName: "pages/dashboard/transaction/index" */))
const _144bfd44 = () => interopDefault(import('../pages/site/aboutus.vue' /* webpackChunkName: "pages/site/aboutus" */))
const _47f9b757 = () => interopDefault(import('../pages/site/contactus.vue' /* webpackChunkName: "pages/site/contactus" */))
const _0c43c06f = () => interopDefault(import('../pages/site/faq.vue' /* webpackChunkName: "pages/site/faq" */))
const _15d1f13e = () => interopDefault(import('../pages/site/privacy.vue' /* webpackChunkName: "pages/site/privacy" */))
const _0c73ff04 = () => interopDefault(import('../pages/streaming/special/index.vue' /* webpackChunkName: "pages/streaming/special/index" */))
const _66df77fd = () => interopDefault(import('../pages/collection/streaming/_id.vue' /* webpackChunkName: "pages/collection/streaming/_id" */))
const _79cf1590 = () => interopDefault(import('../pages/form/_id.vue' /* webpackChunkName: "pages/form/_id" */))
const _64d0fd70 = () => interopDefault(import('../pages/payment/_slug.vue' /* webpackChunkName: "pages/payment/_slug" */))
const _ea6f098e = () => interopDefault(import('../pages/reset/_slug.vue' /* webpackChunkName: "pages/reset/_slug" */))
const _7c0cb70c = () => interopDefault(import('../pages/streaming/_slug.vue' /* webpackChunkName: "pages/streaming/_slug" */))
const _60833777 = () => interopDefault(import('../pages/verification/_slug.vue' /* webpackChunkName: "pages/verification/_slug" */))
const _d1c002fe = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/auth",
    component: _3ab34149,
    name: "auth"
  }, {
    path: "/cart",
    component: _5873d6f8,
    name: "cart"
  }, {
    path: "/home",
    component: _6866ab83,
    name: "home"
  }, {
    path: "/logout",
    component: _053d99eb,
    name: "logout"
  }, {
    path: "/streaming",
    component: _7dc35854,
    name: "streaming"
  }, {
    path: "/wishlist",
    component: _62e9b869,
    name: "wishlist"
  }, {
    path: "/collection/streaming",
    component: _5cca6455,
    name: "collection-streaming"
  }, {
    path: "/dashboard/profile",
    component: _56ca4720,
    name: "dashboard-profile"
  }, {
    path: "/dashboard/transaction",
    component: _4d322415,
    name: "dashboard-transaction"
  }, {
    path: "/site/aboutus",
    component: _144bfd44,
    name: "site-aboutus"
  }, {
    path: "/site/contactus",
    component: _47f9b757,
    name: "site-contactus"
  }, {
    path: "/site/faq",
    component: _0c43c06f,
    name: "site-faq"
  }, {
    path: "/site/privacy",
    component: _15d1f13e,
    name: "site-privacy"
  }, {
    path: "/streaming/special",
    component: _0c73ff04,
    name: "streaming-special"
  }, {
    path: "/collection/streaming/:id",
    component: _66df77fd,
    name: "collection-streaming-id"
  }, {
    path: "/form/:id?",
    component: _79cf1590,
    name: "form-id"
  }, {
    path: "/payment/:slug?",
    component: _64d0fd70,
    name: "payment-slug"
  }, {
    path: "/reset/:slug?",
    component: _ea6f098e,
    name: "reset-slug"
  }, {
    path: "/streaming/:slug",
    component: _7c0cb70c,
    name: "streaming-slug"
  }, {
    path: "/verification/:slug?",
    component: _60833777,
    name: "verification-slug"
  }, {
    path: "/",
    component: _d1c002fe,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
